<template>
  <b-card title="Cadastrados hoje">
    <div v-if="playtimeResults.length > 0" id="export-button">
      <json-excel :data="playtimeResults">
        <feather-icon
          icon="SaveIcon"
          size="40"
          class="ml-2 text-success pointer"
        />
      </json-excel>
    </div>
    <b-table
      v-if="playtimeResults"
      :items="playtimeResults"
      :fields="fields"
      striped
      responsive
      class="data-table"
      :key="k"
    >
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value]}}
        </b-badge>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BBadge,BCardText, BLink
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import JsonExcel from "vue-json-excel";

import {_} from 'vue-underscore';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BLink,
    underscore: _,
    gql,
    JsonExcel
  },
  props:['playtimeResults', 'contextActions', 'addInfo', 'customFields'],
  data() {
    return {
      fields: ['username', 'phone','pix'],
      k:0,
      localInfo:null,
    }
  },
  apollo:{
   
  },
  watch:{
    addInfo: function(){
      this.localInfo = this.addInfo;
    },
  },
  methods:{


  },
  created(){
  }
}
</script>

<style>
  .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  .b-fon{
    width: 25px;
    height: 25px;
  }
  #export-button{
    position: absolute;
    top: 10px;
    right: 10px;
  }
</style>