<template>
  <div>
    <div v-if="!$apollo.loading">
      <!--b-card title="Busque um cliente 🔍">
        <b-card-text>No campo abaixo busque por: Email, Nome, Nickname</b-card-text>
        <b-form-input 
          icon-no-border  
          @keyup.enter.native="searchUser()" 
          placeholder="Digite e pressione ENTER" 
          v-model="searchQuery" 
          class="input-rounded-full" 
          icon="icon-search" 
          icon-pack="feather"
          :state="docState"
          aria-describedby="input-live-help input-live-feedback"
          id="live-query"
        />
        <b-form-invalid-feedback id="input-live-feedback">
          Pelo menos 3 letras
        </b-form-invalid-feedback>
      </b-card-->
      <playtime-list :playtimeResults="usersPlaytime"/>
    </div>
    <div v-else>
      <logo-loader-animation/>
    </div>
  </div>
  
</template>

<script>
import { BCard, BCardText, BLink, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import PlaytimeList from "@/components/promotions/PlaytimeList.vue"
import gql from 'graphql-tag'
import {_} from 'vue-underscore';
import LogoLoaderAnimation from "@/components/misc/LogoLoaderAnimation.vue"

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BFormInput,
    PlaytimeList,
    BFormInvalidFeedback,
    underscore: _,
    LogoLoaderAnimation

  },
  data () {
    return {
      searchQuery: '',
      currentPage: 1,
      usersPlaytime: [],
      queryResult:null,
      pageToSearch:1,
      pageInfo:{},
      today: new Date().toISOString().split('T')[0]
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2 ? true : false
    }
  },
  methods:{
    searchPlays(){
      this.$apollo.query({
        query: gql`query($created_at: String){
          usersPlaytime(
            created_at : $created_at,
          ){
            username,
            phone,
            pix
          }
        }`,
        variables:{
          created_at: this.today
        },
        client: "identityClient"
      }).then((data) => {
        this.usersPlaytime = data.data.usersPlaytime;
      }).catch((error) => {
        console.error(error)
      })
    },
    paginate(eV){
      if(this.pageToSearch == 1){
        this.pageToSearch = 2;

      }else{
        this.pageToSearch = this.pageToSearch + eV;
      }
      this.searchPlays()
    }
  },
  created(){
    this.searchPlays()
  }
}
</script>

<style>

</style>
